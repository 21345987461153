import { Form, Select } from "antd";
import { Rule } from "antd/es/form";
import { DefaultOptionType } from "antd/es/select";
// import "./FormInput.scss";

interface IOption {
  value: string;
  label: string;
}

interface IFormInput {
  name: string;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  disabled?: boolean;
  rules?: Rule[] | undefined;
  options?: IOption[] | undefined;
  defaultValue?: string;
  value?: string;
  mode?: "multiple" | "tags";
  maxTagCount?: "responsive";
  dropdownRender?: any;
  onChange?:
  | ((value: string, option: DefaultOptionType | DefaultOptionType[]) => void)
  | undefined;
  onSelect?: (e:string) => void;
  onSearch?: any;
}

const FormSelect = (props: IFormInput) => {
  const {
    name,
    label,
    isRequired,
    disabled,
    placeholder,
    rules,
    options,
    defaultValue,
    onChange,
    onSelect,
    onSearch,
    value,
    dropdownRender,
    mode,
    maxTagCount,
  } = props;

  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: isRequired,
          message: `Please select your ${label ?? placeholder}`,
        },
        ...(rules ?? []),
      ]}
      initialValue={defaultValue}
    >
      <Select
        size="large"
        defaultValue={defaultValue}
        showSearch
        mode={mode}
        disabled={disabled}
        onChange={onChange}
        onSelect={onSelect}
        onSearch={onSearch}
        placeholder={placeholder}
        maxTagCount={maxTagCount}
        value={value}
        dropdownRender={dropdownRender}
      >
        {/* sort(function (a: any, b: any) {
          if (a.key.toLowerCase() < b.key.toLowerCase()) return -1;
          if (a.key.toLowerCase() > b.key.toLowerCase()) return 1;
          return 0;
        }) */}
        {options?.map((option: any, i: number) => (
            <Select.Option key={i} value={option.key}>
              {option.value}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
};

export default FormSelect;
