import { Typography } from "antd";
import React from "react";
import "./StepsLoan.scss";
import { loanTypeList } from "../start/start";
import { useParams } from "react-router-dom";
import { stages } from "../complementor-widget/ComplementorWidget";
import { isRazorPay } from "../../services/Utility";

const { Title, Text } = Typography;

interface IStepsLoan {
  selectedStage: number;
  isV2: boolean;
  loanType: String;
  selectedStageName: stages;
  complementorUserId?:any;
  extraData: any;
  productFormMetadata: any;
  tmpLoanKey:any;
}

const StepsLoan: React.FunctionComponent<IStepsLoan> = (props: IStepsLoan) => {
  const { selectedStage, isV2, loanType, selectedStageName, complementorUserId, 
    extraData,
    tmpLoanKey,
    productFormMetadata,} = props;

  const { type, id } = useParams() as any;

  const step1 = selectedStage == 1 ? "active" : "completed";
  const step2 =
    selectedStage == 2 ? "active" : selectedStage > 2 ? "completed" : "";
  const step3 =
    selectedStage == 3 ? "active" : selectedStage > 3 ? "completed" : "";

  const renderStep = () => {
    let tmpPLCondition = (type == "pl" && selectedStageName !== "register") ? tmpLoanKey : loanType;
    let strStep2 = "(Business Details)";
    if (((tmpLoanKey?.length ?? 0) > 0 ? tmpPLCondition : loanType)  === "PERSONAL_LOAN_ONLY") {
      strStep2 = "(Salary Details)";
    } else if (loanType === "HOME_LOAN") {
      strStep2 = "(Loan Details)";
    } else if (loanType === "BUY_NOW_PAY_LATER") {
      strStep2 = "(Employer Details)";
    }

    return (
     <>
      <div className={"stepper-wrapper" +(isRazorPay(complementorUserId) ? ' stepper-wrapper_razorPay' :'')}>
        <div className={`stepper-item ${step1}`}>
          <div className="step-counter"></div>
          <div className="step-name">STEP-1</div>
          (Personal Details)
        </div>
        <div className={`stepper-item ${step2}`}>
          <div className="step-counter"></div>
          <div className="step-name">STEP-2</div>
          {strStep2}
        </div>
        <div className={`stepper-item ${step3}`}>
          <div className="step-counter"></div>
          <div className="step-name">STEP-3</div>
          {loanType === "HOME_LOAN" ? "(Offer)" : "(Verify Financials)"}
        </div>
      </div>
     </>
    );
  };

  const renderTitle = () => {
    const title = type == "pl" && selectedStageName == "register" ? "Apply for Loan" :
      loanTypeList?.find((item: any) => item?.key == ((tmpLoanKey?.length ?? 0) > 0 ? tmpLoanKey : loanType))?.label ?? "";

    if(isV2 && selectedStageName == "flowable") {
      const currentStep = extraData?.stepStatus?.find((step: any) => {
        return (
          step?.key == productFormMetadata?.stageKey ||
          step?.key == productFormMetadata?.stepKey
        );
      });

      return (
        <Title level={3} className="title">
          {currentStep?.name}
        </Title>
      );
    } else if(selectedStageName == "bankStatement") { 
      return (
        <Title level={3} className="title">
          {"Upload Bank Statements"}
        </Title>
      );
    } else if(selectedStageName=="offerMultilenderV2"){
      return <></>
    }

    if (id == "CONN2187960526" || id == "CONN1721723276") {
      return (
        <Title level={3} className="title">
          {"Education Loan"}
        </Title>
      );
    }

    const isSub =
      selectedStageName == "register" && loanType === "EDUCATION_LOAN";

    return (
      <>
        <Title level={3} className={`title ${isSub ? "mb-0 " : ""} ${isV2 ? "v2" : ""}`}>
          {loanType !== "BUY_NOW_PAY_LATER" && title}
        </Title>
        {isSub && (
          <Text className="sub-title">
            {"Details related to Parent/Guardian to be entered here"}
          </Text>
        )}
      </>
    );
  };

  return (
    <div className={`main ${isV2 ? "v2" : ""}`}>
      {loanType === "BUY_LEADS" ||
      loanType === "HEALTH_INSURANCE" ||
      loanType === "FOUR_WHEELER_INSURANCE" ||
      loanType === "TWO_WHEELER_INSURANCE" ||
      loanType === "EDUCATION_LOAN"
        ? ""
        :
        <>
        <div className={isRazorPay(complementorUserId) ? 'razor_pay_title' : ''}>
        {!isRazorPay(complementorUserId) && !isV2 && renderStep()}
        {renderTitle()}
        </div>
        </>}
    </div>
  );
};

export default StepsLoan;
