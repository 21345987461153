import { Button, Form, notification } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import FormInput from "../../components/FormInput/FormInput";
import { NUMBER_REGEX } from "../../constants/AppConstants";
import API_SERVICE from "../../services/api-service";
import { stages } from "../complementor-widget/ComplementorWidget";
import "./PersionalDetails.scss";
import { isRazorPay } from "../../services/Utility";
import moment from "moment";
import ReCAPTCHA from 'react-google-recaptcha';
import { loanTypeList } from "../start/start";
import { useParams } from "react-router-dom";

interface IPersionalDetails {
  selectedStage: number;
  setSelectedStage: Function;
  setLeadDetail: Function;
  loanType: String;
  tmpLoanShortCode: string;
  setSelectedStageName: React.Dispatch<React.SetStateAction<stages>>;
  setShowList: Function;
  complementorUserId: String;
  connectorShortDetails: any;
  utmDetailsDto: any;
  getNextTask: Function;
  addGTag: Function;
  uploadLog: (message: string, params: any) => void;
}

const VerifyOTP: React.FunctionComponent<IPersionalDetails> = (
  props: IPersionalDetails
) => {
  const {
    selectedStage,
    setSelectedStage,
    setLeadDetail,
    loanType,
    tmpLoanShortCode,
    setSelectedStageName,
    connectorShortDetails,
    setShowList,
    complementorUserId,
    utmDetailsDto,
    getNextTask,
    addGTag,
    uploadLog,
  } = props;

  const [loadingOTP, setLoadingOTP] = useState(false);
  const [mobile, setMobile] = useState("");
  const [ipAddress, setIPAddress] = useState('')
  const time = 60 * 2 - 1;
  const [countDown, setCountDown] = React.useState(time);
  const [runTimer, setRunTimer] = React.useState(true);
  const [isTimeStoped, setIsTimeStoped] = useState(false);
  const [showCaptchaForConfirmOTP, setShowCaptchaForConfirmOTP] = useState(false);
  const [reCaptchaTokenForConfirmMobile, setReCaptchaTokenForConfirmMobile] = useState("");
  const userStr = localStorage.getItem("user");
  const user = JSON.parse(userStr as string);

  const { type } = useParams() as any;

  useEffect(() => {
    setMobile(user?.mobile);
    getIPAddress();
    checkResendOTP();
  }, []);

  const getIPAddress = async () => {
    API_SERVICE.getIPData()
      .then(({ data }) => {
        setIPAddress(data.ip);
      })
      .catch((e: any) => {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => { });
  };

  function checkResendOTP() {
    setShowCaptchaForConfirmOTP(false);
    setReCaptchaTokenForConfirmMobile("");
    const payload = {
      mobileNumber: user?.mobile,
      reCaptchaScreenName: "OTP"
    }

    API_SERVICE.checkRecaptchaResponse(payload)
      .then(({ data }) => {
        if (data) {
          setShowCaptchaForConfirmOTP(data?.payload?.captchaRequired);
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        //setLoading(false);
      });

  }

  const verifyOtp = (values: any) => {
    setLoadingOTP(true);
    const consentPayload = {
      ipAddress: ipAddress,
      timeStamp: moment().format("MMM DD, YYYY hh:mm"),//moment().locale(“en”).format(“MMM DD, YYYY hh:mm”),
      device: window.navigator.platform,
      tcMessage: "I agree to the T&C, Privacy policy and authorise OneInfinity and or  and hereby authorise OneInfinity and/or its representatives to call,email, Whats APP, or SMS me with reference to my loan application.This consent supersedes any DNC (do not call) registration by me."
      // macAddress: "",
    }
    console.log(consentPayload)
    const data = {
      mobileOtp: values?.otp,
      emailId: "",
      emailOtp: "",
      mobileNumber: user?.mobile,
      verificationId: user?.id,
      caseId: user?.caseId,
      consentPayload: JSON.stringify(consentPayload)
    };
    API_SERVICE.verifyOtp(data)
      .then(({ data }) => {
        if (data) {
          console.log("user: ", user)
          const userModel = { ...user, ...data.payload, mobile: user?.mobile };
          console.log("userModel: ", userModel)
          localStorage.setItem("user", JSON.stringify(userModel));
          notification.success({ message: data.message });
          setSelectedStage(2);
          getLeadList();
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
        // setCountDown(time);
        checkResendOTP();
      })
      .finally(() => {
        setLoadingOTP(false);
      });
  };

  const updateProspect = (prospectId: any) => {
    const user = localStorage.getItem("user");
    // const prospectId = JSON.parse(user as string).prospectId;
    const verificationId = JSON.parse(user as string).id;
    API_SERVICE.updateProspect(prospectId, verificationId)
      .then(({ data }) => { })
      .catch(function (e) { })
      .finally(() => {
        setLoadingOTP(false);
      });
  };

  const getLeadList = () => {
    setLoadingOTP(true);
    const data = {
      pageNo: 1,
      pageSize: 10,
    };

    const loanTypeTmp =
      tmpLoanShortCode?.length > 0 ? loanTypeList?.find((item: any) => item?.code == tmpLoanShortCode)?.key
        : (loanType?.length ?? 0) > 0
          ? loanType
          : loanTypeList?.find((item: any) => item?.code == type)?.key ??
          "BUSINESS_INSTALLMENT_LOAN";

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const id = (urlParams?.sub_connector_id?.length ?? 0) > 0 ? urlParams?.sub_connector_id : complementorUserId;
    const extraparam = `&loanProductShortCode=${loanTypeTmp}&sourceConnectorUserId=${id ?? ""}`
    API_SERVICE.getLeadList(user?.mobile, data, extraparam)
      .then(({ data }) => {
        if (data) {
          if ((data?.payload?.content.length ?? 0) > 0) {
            const tmpLeadDetail = data?.payload?.content[0];
            const caseStatus = tmpLeadDetail?.caseDetails?.caseStatus ?? "";
            const prospectId = tmpLeadDetail?.caseDetails?.prospectId ?? "";
            localStorage.setItem("prospectId", prospectId);

            if (
              (data?.payload?.content?.length ?? 0) === 0 &&
              (connectorShortDetails?.processInstanceId?.length ?? 0) > 0
            ) {
              const tmpleadDetail = {
                prospectId: connectorShortDetails?.dsaCaseId,
                processInstanceId: connectorShortDetails?.processInstanceId,
              };
              getNextTask(tmpleadDetail);
            } else if (
              (data?.payload?.content?.length ?? 0) == 1 &&
              ((caseStatus?.toUpperCase() == "PROSPECT" &&
                tmpLeadDetail?.loanType != "Education Loan") ||
                caseStatus?.toUpperCase() == "LEAD")
            ) {
              const currDropOffStage = tmpLeadDetail?.currDropOffStage ?? "";
              if (currDropOffStage == "OTP_VERIFICATION_PENDING") {
                updateProspect(prospectId);
              }
              let dt = dayjs(
                tmpLeadDetail?.caseDetails?.creationDate,
                "YYYY-MM-DD"
              );
              const diff = dayjs().diff(dt, "day");
              if (diff > 30) {
                setShowList(true);
              } else {
                setLeadDetail(tmpLeadDetail);
                getNextTask(tmpLeadDetail);
              }
            } else {
              setShowList(true);
            }
          } else {
            addCase();
          }
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoadingOTP(false);
      });
  };

  const addCase = () => {
    setLoadingOTP(true);

    let searchParams = new URL(window.location.href).searchParams;
    const urlParams: any = {};
    searchParams?.forEach((value, key) => {
      urlParams[key] = value;
    });

    const consentPayload = {
      ipAddress: ipAddress,
      timeStamp: moment().format("MMM DD, YYYY hh:mm"),
      device: window.navigator.platform,
      tcMessage: "I agree to the T&C, Privacy policy and authorise OneInfinity and or  and hereby authorise OneInfinity and/or its representatives to call,email, Whats APP, or SMS me with reference to my loan application.This consent supersedes any DNC (do not call) registration by me."
    }

    const param = {
      overrideSourceConnectorId: urlParams?.sub_connector_id,
      consentPayload: JSON.stringify(consentPayload),
      workFlowType: "BORROWER_DIRECT",
      utmDetailsDto: utmDetailsDto,
    };

    uploadLog("Add Case Api After Login OTP", param);

    API_SERVICE.addCase(loanType, complementorUserId, urlParams?.ldata, param)
      .then(({ data }) => {
        if (data) {
          uploadLog("Add Case Api After Login Response", { caseId: data?.payload?.caseDetails?.caseId });
          if (data?.payload) {
            const tmpLeadDetail = data?.payload;
            const prospectId = tmpLeadDetail?.caseDetails?.prospectId ?? "";
            localStorage.setItem("prospectId", prospectId);
            updateProspect(prospectId);
            setLeadDetail(tmpLeadDetail);
            getNextTask(tmpLeadDetail);
            setShowList(false);
          } else {
            setShowList(true);
          }
        }
      })
      .catch(function (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      })
      .finally(() => {
        setLoadingOTP(false);
      });
  };

  const onFinish = (values: any) => {
    if ((showCaptchaForConfirmOTP && reCaptchaTokenForConfirmMobile.length === 0)) {
      notification.error({
        message: "Please check reCaptcha",
      });
    } else {
      verifyOtp(values);
      addGTag("verify otp");
    }
  };

  const actionResend = () => {
    if (countDown === 0) {
      if ((showCaptchaForConfirmOTP && reCaptchaTokenForConfirmMobile.length === 0)) {
        notification.error({
          message: "Please check reCaptcha",
        });
      } else {
        checkResendOTP();

        let searchParams = new URL(window.location.href).searchParams;
        const urlParams: any = {};
        searchParams?.forEach((value, key) => {
          urlParams[key] = value;
        });

        const id = (urlParams?.sub_connector_id?.length ?? 0) > 0 ? urlParams?.sub_connector_id : complementorUserId;
        const extraparam = `&sourceConnectorUserId=${id ?? ""}`

        API_SERVICE.getMobileOtp(mobile, extraparam)
          .then(({ data }) => {
            if (data) {
              notification.success({ message: data?.message });

              const userModel = { ...user, id: data?.payload?.id };
              localStorage.setItem("user", JSON.stringify(userModel));

              setCountDown(time);
              setIsTimeStoped(false);
              setRunTimer(true);
            } else {
              notification.error({ message: "no data found" });
            }
          })
          .catch(function (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
          });
      }
    }
  };

  useEffect(() => {
    let timerId = null as any;

    if (runTimer) {
      setCountDown(time);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }
    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown === 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      // setCountDown(0);
    }
  }, [countDown, runTimer]);

  const seconds = String(countDown % 60).padStart(2, "0");
  const minutes = String(Math.floor(countDown / 60)).padStart(2, "0");

  useEffect(() => {
    if (countDown === 0) {
      setIsTimeStoped(true);
    }
  });

  return (
    <div style={{ display: "grid", justifyContent: "center" }}>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
        initialValues={{
          remember: true,
        }}
      >
        <div className={"mb-20" + (isRazorPay(complementorUserId) ? ' razorPay_mb-20' : '')}>{`Enter the OTP sent to +91 ${mobile}`}</div>

        <FormInput
          className={isRazorPay(complementorUserId) ? 'razorPay_input' : ''}
          name="otp"
          type="password"
          inputMode="numeric"
          rules={[
            {
              required: true,
              message: "Please enter your OTP"
            },
            {
              pattern: NUMBER_REGEX,
              message: "Please enter valid OTP",
            },
          ]}
        />
        <br />
        {showCaptchaForConfirmOTP &&
          <div style={{ margin: "auto", display: "table" }}>
            <Form.Item name="reCaptchaToken">
              <ReCAPTCHA
                sitekey="6LctCysqAAAAAFuSCNIHih9gX7V6yZY6SL4NsFWY" // Replace with your site key
                onChange={(token) => setReCaptchaTokenForConfirmMobile(token)}
              />
            </Form.Item>
          </div>
        }
        <div>{/* Time: {minutes}:{seconds} */}</div>

        <Form.Item>
          <span className={isRazorPay(complementorUserId) ? 'razorPay_mb-20' : ''}>Didn't receive the OTP? &nbsp;</span>
          <a onClick={actionResend} style={{ color: "#C71C8E" }} className={isRazorPay(complementorUserId) ? 'razorPay-resendOtp' : ''}>
            {isTimeStoped ? "RESEND OTP" : `${minutes}:${seconds}`}

            <br />
          </a>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size={"large"}
            className={"btn-next" + (isRazorPay(complementorUserId) ? ' razorPay_btn' : '')}
            loading={loadingOTP}
          >
            Next
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VerifyOTP;
